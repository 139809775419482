.s1bc7o4m{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:bold;line-height:120%;font-size:1.5rem;color:var(--s1bc7o4m-1);}
.sf82uqj{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:500;line-height:120%;font-size:1rem;color:var(--sf82uqj-1);}
.s83p0ec{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:500;line-height:120%;font-size:0.875rem;color:var(--s83p0ec-1);}
.sht7b72{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:bold;line-height:120%;font-size:2rem;}
.s1ysnjlf{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:bold;line-height:120%;font-size:1.5rem;}
.s13tz6df{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:bold;line-height:120%;font-size:1rem;}
.soewhg5{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:bold;line-height:120%;font-size:0.875rem;}
.saecux8{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:bold;line-height:120%;font-size:0.75rem;text-transform:uppercase;}
.s14vnoej{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:normal;line-height:140%;font-size:1.5rem;}
.s15tqw0o{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:normal;line-height:140%;font-size:1rem;}
.she121q{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:normal;line-height:140%;font-size:0.875rem;}
.s1yqnrx3{font-family:"Gilroy";font-style:normal;color:#000000;font-weight:normal;line-height:140%;font-size:0.75rem;}
