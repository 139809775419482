html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: white;
  font-family: "gilroy";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  height: 100%;
}
.hide {
  display: none !important;
}

button {
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border-color: currentcolor;
}

body {
  height: 100%;
  /* background: #1c1c1e; */
  /* background: -webkit-linear-gradient(40deg, #1c1c1e 0%, #4c4c4e 100%); */
  /* background: -webkit-linear-gradient(50deg, #1c1c1e 0%, #4c4c4e 100%); */
  /* background: linear-gradient(40deg, #1c1c1e 0%, #4c4c4e 100%); */
  /* background-attachment: fixed; */
  /* background-repeat: no-repeat; */
  font-family: "Roboto", sans-serif;
  z-index: -1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.relative {
  position: relative;
}

.button,
button,
.btn {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600 !important;
  letter-spacing: 0.025em;
}

.prosperstack-iframe--modal--confirmation {
  min-height: 180px !important;
}

@media screen and (max-width: 769px) {
  .intercom-lightweight-app-launcher,
  .intercom-launcher-frame {
    left: auto !important;
    right: 1.25rem !important;
    bottom: 0.5rem !important;
  }
}
