.czegxj8{position:fixed;left:0;right:0;top:0;bottom:0;z-index:10005;width:343px;height:auto;max-height:-webkit-max-content;max-height:-moz-max-content;max-height:max-content;background:#FFFFFF;margin:auto;border-radius:16px;}@media only screen and (min-width:769px){.czegxj8{width:636px;}}
.c1j50zkk{position:absolute;right:24px;top:24px;cursor:pointer;}.c1j50zkk span{font-weight:600;}
.d9mep16{width:100%;height:1px !important;border-top:1px dashed #D1D1D6 !important;margin:16px 0 !important;padding:0 !important;}
.w1ojq8vn{width:100%;border-radius:8px;background:rgba(255,192,67,0.15);height:72px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:16px;padding:16px;margin-bottom:var(--w1ojq8vn-0);}.w1ojq8vn > h3{color:#966a13;font-size:14px;font-weight:500;line-height:20px;text-transform:inherit;}.w1ojq8vn svg{width:30px;}
.c1ivpu8u{width:100%;border-radius:8px;background:rgba(225,25,0,0.15);height:72px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:16px;padding:16px;margin-bottom:48px;}.c1ivpu8u > h3{color:rgba(225,25,0,1);font-size:14px;font-weight:500;line-height:20px;text-transform:inherit;}.c1ivpu8u > h3 span{color:rgba(225,25,0,1);font-size:14px;font-weight:700;line-height:20px;text-transform:inherit;-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer;}.c1ivpu8u svg{width:30px;}
.bef5fqo{width:100%;}
.b459rso{width:100%;height:240px;border-radius:16px;background:#eeeeee;padding:40px;}.b459rso h3{margin-bottom:24px;color:#000000;text-align:center;font-family:"Neusa Next Pro";font-size:20px;font-weight:700;line-height:26px;}.b459rso > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.b12ede8u{width:100%;height:120px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:8px;}.b12ede8u > button{width:100%;height:56px !important;padding:16px 32px !important;}.b12ede8u > button > div span{font-size:18px !important;font-weight:600 !important;line-height:24px !important;}.b12ede8u button:nth-child(1){color:#ffffff;}.b12ede8u button:nth-child(2){background:transparent;color:#000000;}.b12ede8u button:nth-child(2):hover:active{background-color:transparent !important;}
.p1geg4ew{width:202px;height:110px;border-radius:8px;background:#ffffff;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.p1geg4ew h4{color:#000000;text-align:center;font-family:"Neusa Next Pro";font-size:28px;font-weight:700;line-height:36px;}.p1geg4ew span{color:#8e8e93;text-align:center;font-size:16px;font-weight:500;line-height:24px;text-transform:capitalize;}
.a922hwz{width:40px;height:40px;border-radius:99px;background:#0d41eb;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.a922hwz .icon-Arrow--Right{color:#ffffff;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.b1erdk5z{width:36px;height:36px;border-radius:99px;background:#eeeeee;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;cursor:pointer;position:absolute;left:24px;top:24px;}.b1erdk5z .icon-Angle--Left{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;font-weight:600;}
