.c1samhtm{width:100%;background:#ffffff;padding:8px 16px 69px 16px;}@media only screen and (min-width:1025px){.c1samhtm{max-width:1280px;}}@media only screen and (min-width:769px){.c1samhtm{max-width:1280px;padding:var(--c1samhtm-2);background:transparent;}}@media (min-width:1500px){.c1samhtm{max-width:1440px;}}@media (max-width:1300px){.c1samhtm{max-width:1000px;}}@media (max-width:1000px){.c1samhtm{max-width:850px;}}
.m1hsaalt{border-radius:16px;border:1px solid #D1D1D6;background:#FFFFFF;padding:32px;}
.h1ltnivc{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}.h1ltnivc h1{color:#000000;font-size:24px;font-weight:600;line-height:32px;text-transform:capitalize;}.h1ltnivc > div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:16px;}
.i1k8aycy button{border-radius:99px;background:#0d41e1;height:32px;padding:8px 12px;}.i1k8aycy button:hover:active{background:#0d41e1;}.i1k8aycy button span{color:#ffffff;font-size:14px;font-weight:600;line-height:16px;}
.ugdqlfj button{border-radius:99px;border:1px solid #000000;background:#FFFFFF;height:32px;padding:8px 12px;}.ugdqlfj button:hover:active{background:#FFFFFF;}.ugdqlfj button span{color:#1A1A1A;font-size:14px;font-weight:600;line-height:16px;}
.dom1fgr{width:100%;height:1px;border-top:1px dashed #D1D1D6;margin:32px 0;}
.m11sh57i{display:grid;grid-template-columns:1fr 1fr 264px;gap:32px;}.m11sh57i > div{border-radius:8px;background:#F6F6F6;padding:32px;width:100%;height:128px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}.m11sh57i > div h2{color:#000000;font-family:"Neusa Next Pro";font-size:40px;font-weight:700;line-height:48px;text-transform:uppercase;margin-bottom:8px;}.m11sh57i > div > span{color:#8E8E93;font-size:16px;font-weight:500;line-height:24px;text-transform:capitalize;}@media (max-width:1300px){.m11sh57i{grid-template-columns:repeat(2,1fr);}}@media (max-width:500px){.m11sh57i{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}
.a14e54tb h2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:1px;}.a14e54tb h2 > div{border-radius:99px;background:#0d41e1;padding:2px 6px;color:#FFFFFF;font-size:12px;font-weight:600;line-height:16px;margin-bottom:6px;margin-left:6px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.a14e54tb h2 .icon-Arrow--Right{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}.a14e54tb h2 .icon-Arrow--Right:before{font-size:8px;}@media (max-width:1000px){.a14e54tb{min-width:100%;}}
@media (max-width:1000px){.pybmai3{min-width:100%;}}
.m13hxrn1 h2{font-size:24px !important;}@media only screen and (min-width:769px){.m13hxrn1{-webkit-align-items:flex-end !important;-webkit-box-align:flex-end !important;-ms-flex-align:flex-end !important;align-items:flex-end !important;}}
.izpw911{grid-column:1 / -1;}.izpw911 > button{border-radius:100px;background:#0d41e1;width:100%;height:44px;}.izpw911 > button span{color:#ffffff;font-size:18px;font-weight:600;line-height:24px;}
