.c8rendz .overlay{z-index:10000;}
.t1q5zq8o{border-radius:16px;}.t1q5zq8o > span{font-weight:600;font-size:14px;line-height:16px;color:#8E8E93;}
.tfew8xn{width:100%;border-spacing:0;margin-bottom:80px;margin-top:16px;border-radius:16px;border:1px solid #D1D1D6;}
.tqsl1ba{border-radius:16px;border-bottom:1px solid #D1D1D6;}.tqsl1ba > tr{height:54px;border-radius:16px;}.tqsl1ba > tr > th{font-weight:700;text-align:initial;border-bottom:1px solid #D1D1D6;position:relative;}.tqsl1ba > tr > th > span{font-weight:600;font-size:14px;line-height:16px;color:#000000;text-transform:initial;}.tqsl1ba > tr > th:first-child{padding-left:24px;}
.tbsqi4o tr{padding-left:28px;background:#ffffff;border:1px solid #D1D1D6;text-transform:capitalize;}.tbsqi4o tr > td{padding:16px 0;border-bottom:1px solid #D1D1D6;position:relative;max-width:180px;}.tbsqi4o tr > td > span{color:#8e8e93;font-weight:600;font-size:var(--tbsqi4o-2);line-height:16px;text-align:initial;text-transform:capitalize;}.tbsqi4o tr > td:first-child{padding-left:24px;}@media only screen and (min-width:500px){.tbsqi4o tr > td:first-child{padding-right:28px !important;}}.tbsqi4o > tr:last-child > td{border-bottom:none;}.tbsqi4o > tr:last-child > td:nth-child(1){border-bottom:none;border-radius:0 0 0 16px;}.tbsqi4o > tr:last-child > td:nth-child(5){border-bottom:none;border-radius:0 0 16px 0;}
.m12hkysg .more-menu > span:before{color:#8E8E93;}.m12hkysg .menu{box-shadow:0px 2px 8px rgba(0,0,0,0.4);border-radius:8px;background:#1A1A1A;right:-14px !important;left:auto !important;bottom:unset !important;top:30px !important;}.m12hkysg .menu > div{padding:10px 12px;border-radius:0;}.m12hkysg .menu > div > span{font-weight:600;font-size:12px;line-height:16px;}.m12hkysg .menu > div:nth-child(2){border-top:1px solid #333333;}
.n1yoxt0v{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:144px 0;}.n1yoxt0v > p{color:#000000;font-weight:normal;line-height:140%;font-size:16px;}
.s10sw5p3{padding:2px 6px;border-radius:2px;border:var(--s10sw5p3-0);color:var(--s10sw5p3-1);font-size:12px;font-weight:600;line-height:16px;text-transform:capitalize;width:-webkit-max-content;width:-moz-max-content;width:max-content;}
.s13cb372{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:16px;background:#FFFFFF;border-radius:16px;margin-top:32px;}
.dywia8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:16px;}
.b6g3sh7{position:fixed;max-width:-webkit-max-content;max-width:-moz-max-content;max-width:max-content;margin-bottom:0;background-color:#1A1A1A;left:0;right:0;box-shadow:0px 16px 48px 0px rgba(0,0,0,0.22);border-radius:8px;margin:0 auto;bottom:32px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;height:68px;box-shadow:0px 4px 16px 0px rgba(0,0,0,0.14);gap:31px;padding:0 24px;}
.m1yr3md2{color:#FFFFFF;font-size:14px;font-weight:600;line-height:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.ra9ehk9{color:#8E8E93;font-size:14px;font-weight:600;line-height:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:10px;cursor:pointer;}.ra9ehk9:hover{border-radius:4px;background:#333333;color:#FFFFFF;}
.do0bxrd{color:#8E8E93;font-size:14px;font-weight:600;line-height:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:10px;cursor:pointer;}.do0bxrd:hover{border-radius:4px;background:#333333;color:#FFFFFF;}
.cxrqk12{width:16px;height:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;border-radius:100px;background:#FFFFFF;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.cxrqk12 .icon-X{font-size:7px;font-weight:600;cursor:pointer;}
.a602jvn{border-radius:99px;border:1px solid #0d41e1;padding:2px 8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;color:#0d41e1;font-size:12px;font-weight:500;line-height:16px;width:51px;margin-left:16px;}
.iiqhx3p{width:20px;height:20px !important;display:-webkit-box !important;display:-webkit-flex !important;display:-ms-flexbox !important;display:flex !important;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:8px;}.iiqhx3p input{position:absolute;z-index:-1;opacity:0;}.iiqhx3p .checkbox{position:absolute;width:14px !important;height:14px !important;box-sizing:content-box;border-radius:2px;}.iiqhx3p .checkbox .icon-Success{font-size:14px;}.iiqhx3p .checkbox .icon-Success:before{color:#000000;}.iiqhx3p > label{position:relative !important;left:0 !important;padding:0 !important;cursor:var(--iiqhx3p-1) !important;display:-webkit-box !important;display:-webkit-flex !important;display:-ms-flexbox !important;display:flex !important;-webkit-align-items:center !important;-webkit-box-align:center !important;-ms-flex-align:center !important;align-items:center !important;}.iiqhx3p > label > p{width:-webkit-max-content;width:-moz-max-content;width:max-content;margin-left:28px;margin-top:1.5px;font-size:14px;line-height:16px;}
