.clg4r54{display:var(--clg4r54-0);position:fixed;height:100%;top:0;bottom:0;left:0;right:0;background:var(--clg4r54-1);z-index:1000;overflow-y:var(--clg4r54-2);}.clg4r54::-webkit-scrollbar{display:none !important;}.clg4r54 > div:last-child{position:absolute;max-width:100%;width:100%;top:auto;height:auto;min-height:162px;border-radius:0;z-index:2;box-shadow:none;background:var(--clg4r54-1);}.clg4r54 > div:last-child > div:nth-child(1){margin-bottom:var(--clg4r54-4);max-width:var(--clg4r54-5);height:var(--clg4r54-6);}.clg4r54 > div:last-child > div:nth-child(1) span{font-weight:var(--clg4r54-7);font-size:var(--clg4r54-8);line-height:var(--clg4r54-9);color:#000000;}.clg4r54 > div:last-child > div:nth-child(2) > div{margin-bottom:0 !important;background:#f9e643;border-radius:8px;padding:16px 24px !important;}.clg4r54 > div:last-child > div:nth-child(2) > div > span{font-weight:600;font-size:18px;line-height:24px;color:#000000;}
.h19u82ts{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:52px;padding:0 16px;}.h19u82ts > div:nth-child(2){-webkit-order:3;-ms-flex-order:3;order:3;}.h19u82ts > div:nth-child(2) > div{margin:0;background:#8E8E93;height:1.8rem;width:1.8rem;}.h19u82ts > div:nth-child(2) > div > div:nth-child(1) > div{border:1px solid #000000;height:24px;width:24px;}.h19u82ts > div:nth-child(2) > div > div:nth-child(2){z-index:1000;top:68px;bottom:initial;}.h19u82ts > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(1) > div{max-width:185px;}.h19u82ts > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(1) > div h1{white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}.h19u82ts > div:nth-child(2) > div > div:nth-child(3){background:#000000;opacity:0.6;}.h19u82ts > button{margin-left:auto !important;font-weight:600;font-size:18px;line-height:24px;color:#000000;-webkit-text-decoration:underline;text-decoration:underline;margin-right:16px !important;}.h19u82ts button{border:0;padding:0;cursor:pointer;margin:0;}
.sdlmbvh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:52px;padding:0 16px;background:#000000;position:fixed;left:0;right:0;top:0;bottom:0;z-index:1001;}.sdlmbvh > div:nth-child(2){-webkit-order:3;-ms-flex-order:3;order:3;}.sdlmbvh > div:nth-child(2) > div{margin:0;background:#8E8E93;height:1.8rem;width:1.8rem;}.sdlmbvh > div:nth-child(2) > div > div:nth-child(1) > div{border:1px solid #000000;height:24px;width:24px;}.sdlmbvh > div:nth-child(2) > div > div:nth-child(2){z-index:1000;top:68px;bottom:initial;}.sdlmbvh > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(1) > div{max-width:185px;}.sdlmbvh > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(1) > div h1{white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}.sdlmbvh > div:nth-child(2) > div > div:nth-child(3){background:#000000;opacity:0.6;}.sdlmbvh > button{margin-left:auto !important;font-weight:600;font-size:18px;line-height:24px;color:#000000;-webkit-text-decoration:underline;text-decoration:underline;margin-right:16px !important;}.sdlmbvh button{border:0;padding:0;cursor:pointer;margin:0;}
.mc3gk3v > div{width:100%;margin-bottom:16px;margin-top:var(--mc3gk3v-0);}.mc3gk3v > div > img{width:100%;height:var(--mc3gk3v-1);object-fit:cover;}.mc3gk3v > div > h2{font-weight:600;font-size:20px;line-height:26px;color:#1A1A1A;text-transform:initial;margin-left:16px;}
.w1kw55ms{background:#000000;padding:48px 24px;margin-bottom:0 !important;margin-top:0 !important;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.w1kw55ms h1{color:#ffffff;text-align:center;font-size:32px;font-weight:500;line-height:40px;-webkit-letter-spacing:-1.44px;-moz-letter-spacing:-1.44px;-ms-letter-spacing:-1.44px;letter-spacing:-1.44px;margin-bottom:12px;}.w1kw55ms p{color:#d1d1d6;text-align:center;font-size:16px;font-weight:500;line-height:24px;margin-bottom:32px;}.w1kw55ms button{border-radius:100px;background:#f9e643;width:100%;padding:16px 20px;margin-bottom:32px;}.w1kw55ms button > span{color:#000000;font-size:18px;font-weight:600;line-height:24px;}.w1kw55ms > span{color:#8e8e93;text-align:center;font-size:14px;font-weight:600;line-height:16px;}
.s1yo35j2{background:#1a1a1a;padding:32px 24px 64px 24px;margin-bottom:0 !important;margin-top:0 !important;}
.dv8sduf > div > div{color:#8e8e93;font-size:16px;font-weight:600;line-height:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}.dv8sduf > div > div > span{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.d1pj0e05 > div{padding:var(--d1pj0e05-0);}
.ixqb4y5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-bottom:0 !important;padding:0 !important;}.ixqb4y5 > div{margin-bottom:16px;color:#ffffff;font-size:16px;font-weight:500;line-height:24px;}
.s13onne6{padding:40px 0 32px 0;}.s13onne6 > div{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:0 24px;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.l1w8jocz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.c1xf0pro{width:100%;margin-bottom:16px;}.c1xf0pro > div:nth-child(1){position:relative;}.c1xf0pro > div:nth-child(1) > img{width:100%;height:var(--c1xf0pro-0);object-fit:cover;}.c1xf0pro > div:nth-child(1) h1{font-weight:600;font-size:36px;line-height:44px;color:#FFFFFF;text-transform:initial;position:absolute;bottom:24px;left:16px;max-width:260px;}.c1xf0pro > div:nth-child(1) > div{position:absolute;height:100%;width:100%;background:linear-gradient( 180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100% );}.c1xf0pro div{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.c1xf0pro > div:nth-child(2){padding:0 16px;border-bottom:1px solid #8e8e93;height:58px;background:#EEEEEE;}.c1xf0pro > div:nth-child(2) h2{font-weight:600;font-size:20px;line-height:26px;color:#1A1A1A;text-transform:initial;}.c1xf0pro > div:nth-child(2) span{font-weight:500;font-size:16px;line-height:24px;color:#1A1A1A;}.c1xf0pro > div:nth-child(3){-webkit-box-pack:initial;-webkit-justify-content:initial;-ms-flex-pack:initial;justify-content:initial;min-height:48px;padding:0 16px;background:#EEEEEE;}.c1xf0pro > div:nth-child(3) span{font-weight:500;font-size:16px;line-height:24px;color:#1A1A1A;}.c1xf0pro p{font-weight:500;font-size:14px;line-height:20px;color:#1A1A1A;padding:8px 16px 16px 16px;background:#EEEEEE;}
.dsswoeo{background:transparent !important;}.dsswoeo h1{font-size:36px;font-weight:500;line-height:40px;-webkit-letter-spacing:-1.44px;-moz-letter-spacing:-1.44px;-ms-letter-spacing:-1.44px;letter-spacing:-1.44px;text-transform:capitalize;}.dsswoeo h1 span{font-size:36px;font-weight:500;line-height:40px;-webkit-letter-spacing:-1.44px;-moz-letter-spacing:-1.44px;-ms-letter-spacing:-1.44px;letter-spacing:-1.44px;text-transform:lowercase;}.dsswoeo p{color:#d1d1d6;font-size:16px;font-weight:500;line-height:24px;margin-bottom:0;}
.d4vv8dc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:49px;position:relative;}.d4vv8dc > div{width:100%;height:1px;border-top:1px dashed #8e8e93;margin:32px 0;}.d4vv8dc span{position:absolute;top:0;bottom:0;left:0;right:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin:auto;color:#d1d1d6;font-size:12px;font-weight:500;line-height:16px;}
.s19gdonb > div{height:302px;width:100%;margin-bottom:32px;background-color:var(--s19gdonb-0);}
