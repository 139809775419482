.ab-feed-buttons-wrapper {
  box-shadow: none !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 20px;
  height: 68px !important;
  background: #000000 !important;
  width: 100%;
}

.button-refresh {
  border: 0;
  padding: 0;
  cursor: pointer;
  height: 18px;
}

.ab-feed-buttons-wrapper .c1mcbcob {
  display: none;
}

.fa-refresh {
  height: min-content !important;
}

.fa-refresh:before {
  display: none !important;
}

.ab-feed {
  margin: auto;
  position: absolute;
  background: #ffffff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 391px;
}

.ab-feed::-webkit-scrollbar {
  display: none !important;
}

.ab-refresh-button {
  display: none !important;
}

.ab-no-cards-message-container {
  position: absolute;
  top: 50%;
  text-align: center;
  margin-bottom: 20px;
  font-family: "Neusa Next Pro" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #1a1a1a !important;
  margin-left: 0 !important;
  text-transform: uppercase;
  width: 343px !important;
}

.ab-no-cards-message-container p {
  font-family: "Gilroy" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #8e8e93 !important;
  text-transform: none !important;
  margin-top: 12px;
  width: 267px !important;
  margin: 0 auto;
  margin-top: 12px;
}

.ab-feed-body {
  border: none !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  background: #ffffff !important;
  padding: 68px 24px 0 24px !important;
}

.ab-card {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  border-bottom: 1px solid #eeeeee !important;
  margin-bottom: 0 !important;
}

.ab-card .ab-close-button {
  padding-right: 0 !important;
  width: 12px !important;
  height: 12px !important;
}

.ab-card-body {
  background: #ffffff;
  border-radius: 4px !important;
  padding-bottom: 16px !important;
  padding-top: 24px !important;
}

.with-image .ab-image-area {
  padding: 24px 16px 0 0 !important;
}

.ab-captioned-image .ab-card-body {
  padding-top: 16px !important;
}

.ab-captioned-image .ab-card-body {
  border-radius: 0 !important;
}

.ab-title {
  font-family: "Gilroy" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: #1a1a1a !important;
  text-transform: initial !important;
  padding: 0 !important;
}

.ab-description {
  font-family: "Gilroy" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #8e8e93 !important;
  padding: 8px 0 0 0 !important;
}

.read {
  display: none !important;
}

.ab-close-button {
  padding-right: 20px !important;
  display: flex !important;
  align-items: center !important;
  cursor: inherit !important;
  float: right !important;
  opacity: 1 !important;
  order: 3;
}

.fa-times:hover {
  padding-top: 16px !important;
  padding-right: 20px !important;
}

.ab-feed .ab-feed-buttons-wrapper .ab-close-button:hover,
.ab-feed .ab-feed-buttons-wrapper .ab-refresh-button:hover {
  font-size: 24px !important;
}
.ab-image-area {
  z-index: 1;
}

.ab-url-area {
  font-family: "Gilroy" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #1a1a1a !important;
  margin-top: 16px !important;
}

.ab-url-area a {
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid #000000;
}

.ab-unread-indicator {
  background-color: #000000 !important;
}

.fa-times:before {
  display: none !important;
}

.fa-times span {
  color: white;
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
  font-size: 20px !important;
}

.ab-pinned-indicator {
  z-index: 4;
  border-color: transparent #000000 transparent transparent !important;
}

.fa-star {
  color: #ffffff !important;
}

@media screen and (max-width: 600px) {
  .ab-feed-buttons-wrapper {
    height: 56px !important;
    display: block !important;
    width: 100% !important;
  }

  .button-refresh {
    display: none !important;
  }

  .ab-feed {
    margin-bottom: 60px;
  }

  .ab-feed-buttons-wrapper .c1mcbcob {
    display: block;
    margin-top: 10px;
  }

  .fa-times span {
    font-size: 24px !important;
  }
}
